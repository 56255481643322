import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import { AdminLogin } from "../../../services/api";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertmessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [mailValue, setMailValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const [choosenStatus, setChoosenStatus] = useState(
    "https://gatewaydev.goldtaggateway.com/Admin/"
  );
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState([
    { id: 1, Name: "https://gateway.goldtaggateway.com/Admin/" },
    { id: 2, Name: "https://gatewaydev.goldtaggateway.com/Admin/" },
  ]);

  async function sendAuthCode(mailValue, passValue) {
    setLoading(true);

    try {
      const response = await AdminLogin(mailValue, passValue);
      console.log("that the response:", response);

      if (response.Success) {
        setAlertMessage(response.Message);
        setAlertStatus(true);
        setOpenAlert(true);
        if (response.Data.TwoFactorRequired) {
          navigate("/auth", {
            state: { mailValue: mailValue, UserId: response.Data.Id },
          });
        }
      } else if (response.Success == false) {
        if (!response.Data.TwoFactorRequired) {
          navigate("/qrAuth", { state: { id: response.Data.Id } });
        } else {
          setAlertMessage(response.Message);
          setAlertStatus(false);
          setOpenAlert(true);
        }
      }
    } catch (error) {
      setAlertMessage("Bir şeyler ters gitti! | Catch ERROR");
      setAlertStatus(false);
      setOpenAlert(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleChooseStatus = (name) => {
    localStorage.setItem("fetchUrl", name.target.value);
    setChoosenStatus(name.target.value);

    console.log(localStorage.getItem("fetchUrl"));
  };

  useEffect(() => {
    localStorage.setItem("fetchUrl", choosenStatus);
    console.log(choosenStatus);
  }, [choosenStatus]);

  return (
    <>
      <Box sx={{ width: "367px" }}>
        <TextField
          name="email"
          label="Email adresi"
          value={mailValue}
          fullWidth
          onChange={(e) => setMailValue(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: "background.default",
            },
          }}
          sx={{ marginBottom: "1rem" }}
        />

        <TextField
          name="password"
          label="Parola"
          type={showPassword ? "text" : "password"}
          value={passValue}
          fullWidth
          onChange={(e) => setPassValue(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: "background.default",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: "1rem" }}
        />

        <FormControl sx={{ width: "160px" }}>
          <InputLabel id="demo-simple-select-label">URL Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenStatus}
            label="URL Seçiniz"
            onChange={handleChooseStatus}
            style={{ width: "15rem" }}
          >
            {res.map((res) => (
              <MenuItem key={res.Name} value={res.Name}>
                {res.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {loginStatus ? (
          ""
        ) : (
          <Typography
            sx={{
              color: "red",
              alignSelf: "center",
              marginTop: "1rem",
              fontStyle: "italic",
            }}
          >
            {loginMessage}
          </Typography>
        )}

        <Button
          sx={{ marginTop: "1rem" }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={() => {
            sendAuthCode(mailValue, passValue);
          }}
        >
          Giriş Yap
        </Button>
        <Snackbar
          open={openAlert}
          onClose={handleAlertClose}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity={alertStatus ? "success" : "error"}>
            {alertmessage}
          </Alert>
        </Snackbar>
        <LoadingOverlay isOpen={loading} />
      </Box>
    </>
  );
}
